import * as React from "react";
import styled from "styled-components";
import { useDrop } from "react-dnd";

import {
  BoardCell,
  DropItem,
  DRAG_TYPES,
  TileLocation,
  Tile,
  Bonus,
} from "shared/wordery/types";
import TileComponent, { TILE_SIZE } from "./Tile";
import { useRecoilState } from "recoil";
import {
  CursorDirection,
  cursorDirectionAtom,
  selectedCellAtom,
  useDropTileOnBoard,
} from "./recoil";
import { equals } from "lodash/fp";
import { useMemo, useCallback } from "react";
import BoardCellEmpty from "./BoardCellEmpty";

export const CELL_SIZE = TILE_SIZE + 10;

const bonusStyling = ({ bonus }: BoardCellContainerProps) => {
  switch (bonus) {
    case Bonus.DoubleLetter:
      return "background-color: lightblue";
    case Bonus.DoubleWord:
      return "background-color: pink";
    default:
      return "";
  }
};

interface BoardCellContainerProps {
  isHovering: boolean;
  bonus: Bonus | null;
}

const BoardCellContainer = styled.div<BoardCellContainerProps>`
  background-color: ${props => (props.isHovering ? "yellow" : "white")};
  box-sizing: border-box;
  border: ${props => (props.isHovering ? "2px dashed black" : "none")};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Consolas", sans-serif;
  font-size: ${(CELL_SIZE * 20) / 70}px;
  ${bonusStyling}
`;

const useOnClickCellCallback = (
  row: number,
  col: number,
  contents: Tile | null
) => {
  const [selectedCell, setSelectedCell] = useRecoilState(selectedCellAtom);
  const [cursorDirection, setCursorDirection] = useRecoilState(
    cursorDirectionAtom
  );
  return useCallback(() => {
    if (contents) return;
    if (!equals([row, col], selectedCell)) {
      if (!selectedCell) {
        setCursorDirection(CursorDirection.RIGHT);
      }
      setSelectedCell([row, col]);
    } else {
      if (cursorDirection === CursorDirection.DOWN) {
        setSelectedCell(null);
      } else if (cursorDirection === CursorDirection.RIGHT) {
        setCursorDirection(CursorDirection.DOWN);
      }
    }
  }, [
    contents,
    col,
    row,
    selectedCell,
    setSelectedCell,
    cursorDirection,
    setCursorDirection,
  ]);
};

interface BoardCellProps {
  boardCell: BoardCell;
  row: number;
  col: number;
}

const BoardCellComponent = ({ row, col, boardCell }: BoardCellProps) => {
  const { bonus, contents } = boardCell;
  const onDrop = useDropTileOnBoard();
  const onClick = useOnClickCellCallback(row, col, contents);
  const [{ isHovering }, dropRef] = useDrop({
    accept: DRAG_TYPES.BOARD_TILE,
    drop: (item: DropItem) => {
      const { tile, source } = item;
      onDrop(tile, source, row, col, contents);
    },
    collect: monitor => ({
      item: monitor.getItem(),
      isHovering: monitor.isOver(),
    }),
  });
  const source = useMemo(
    () => ({
      location: TileLocation.Board,
      position: [row, col],
    }),
    [row, col]
  );
  return (
    <BoardCellContainer
      ref={dropRef as any}
      isHovering={isHovering}
      onClick={onClick}
      bonus={bonus}
    >
      {contents && <TileComponent tile={contents} source={source} />}
      {!contents && <BoardCellEmpty bonus={bonus} row={row} col={col} />}
    </BoardCellContainer>
  );
};

export default React.memo(BoardCellComponent);

import { Routes, Route } from "react-router-dom";
import NavBar from "./components/NavBar";
import About from "./pages/About";
import WorderyPage from "./pages/Wordery";
import { RecoilRoot } from "recoil";
import PostEditor from "./pages/posts/PostEditor";
import Post from "./pages/posts/Post";
import PostList from "./pages/posts/PostList";
import Wordsquared from "./wordsquared";
import Boxing from "./pages/boxing/index";
import SSBMRank from "./pages/ssbmrank";
import AnagramGame from "./pages/anagrams";
// import WebsocketPlayground from "./pages/WebsocketPlayground";
import Tunnel from "./pages/Tunnel";
import Chat from "./pages/Chat";

const App = () => {
  return (
    <RecoilRoot>
      <NavBar />
      <Routes>
        <Route path="about" element={<About />} />
        <Route path="wordery/:id" element={<WorderyPage />} />
        <Route path="wordery" element={<WorderyPage />} />
        <Route path="posts" element={<PostList />} />
        <Route path="posts/:slug" element={<Post />} />
        <Route path="mysecretpostmaker" element={<PostEditor />} />
        <Route path="ws" element={<Wordsquared />} />
        <Route path="boxing" element={<Boxing />} />
        <Route path="anagrams" element={<AnagramGame />} />
        <Route path="ssbmrank" element={<SSBMRank />} />
        {/* <Route path="ws" element={<WebsocketPlayground />} /> */}
        <Route path="tunnel" element={<Tunnel />} />
        <Route path="chat" element={<Chat />} />
        <Route path="" element={<About />} />
      </Routes>
    </RecoilRoot>
  );
};

export default App;

import Background from "src/components/Background";
import Container from "src/components/Container";
import styled from "styled-components";
import Title from "src/components/Title";
import React, { useCallback, useRef, useState } from "react";
import HeadIcon from "./HeadIcon";
import { Ranking, RanksJson } from "./types";
import { RANKINGS } from "./ranks";
// import withDraggableScroll from "src/components/withDraggableScroll";
import PlayerTooltips from "./PlayerTooltips";
import MetaTags from "../../components/MetaTags";
import filterRankings from "./filterRankings";
import { useDraggable } from "react-use-draggable-scroll";

const SSBMRankContainer = styled(Container)`
  width: 90%;
  text-align: center;
`;

const AllRanks = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  overflow: scroll;
  padding: 5px;
  cursor: grab;
`;

const Options = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const FilterLabel = styled.label`
  margin-left: 10px;
`;

const FilterInput = styled.input.attrs({ type: "text" })`
  height: 24px;
`;

const RankingTableContainer = styled.div`
  margin: 5px;
`;

const RankingTableComponent = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  text-align: left;
  border: 1px solid black;
`;

const RankingTableCell = styled.div`
  padding: 4px 2px 4px 2px;
  white-space: nowrap;
  :nth-child(6n + 1),
  :nth-child(6n + 2),
  :nth-child(6n + 3) {
    background-color: lightgray;
  }
`;

const RankingTableNumber = styled(RankingTableCell)`
  padding-left: 8px;
`;

const RankingTableName = styled(RankingTableCell)``;

const RankingTableCharacters = styled(RankingTableCell)`
  padding-right: 8px;
  user-select: none;
`;

interface RankingTableProps {
  ranking: Ranking;
  hidden: boolean;
}
const RankingTable: React.FC<RankingTableProps> = ({ ranking, hidden }) => {
  return (
    <RankingTableContainer key={ranking.name} hidden={hidden}>
      <strong style={{ whiteSpace: "nowrap" }}>{ranking.name}</strong>
      <RankingTableComponent>
        {ranking.ranks.map(playerRank => (
          <React.Fragment key={`row-${playerRank.tag}`}>
            <RankingTableNumber>{playerRank.number}.</RankingTableNumber>
            <RankingTableName
              key={`tag-${playerRank.tag}`}
              data-tip
              data-for={`tooltip-${playerRank.tag}`}
            >
              {playerRank.tag}
            </RankingTableName>
            <RankingTableCharacters>
              {playerRank.characters.map(character => (
                <HeadIcon
                  key={`$${playerRank.tag}-${character}`}
                  character={character}
                />
              ))}
            </RankingTableCharacters>
          </React.Fragment>
        ))}
      </RankingTableComponent>
    </RankingTableContainer>
  );
};

interface RankingTablesProps {
  showSummer: boolean;
  rankings: RanksJson;
}

const UnwrappedRankingTables = React.forwardRef<
  HTMLDivElement,
  RankingTablesProps
  >(({ rankings, showSummer, ...rest }) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const { events } = useDraggable(ref as any);
    return (
      <AllRanks ref={ref} {...events} {...rest}>
        {rankings.map(ranking => (
          <RankingTable
            key={ranking.name}
            ranking={ranking}
            hidden={!showSummer && ranking.isSummer}
          />
        ))}
      </AllRanks>
    );
});

const RankingTables = React.memo(UnwrappedRankingTables);

const SSBMRank = () => {
  const [showSummer, setShowSummer] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");
  const [filteredRankings, setFilteredRankings] = useState<RanksJson>(RANKINGS);
  const onSearch = useCallback(
    event => {
      event.preventDefault();
      const rankings = filterRankings(RANKINGS, query);
      setFilteredRankings(rankings);
    },
    [query]
  );
  const onCheck = useCallback(event => setShowSummer(event.target.checked), []);
  const onChangeFilter = useCallback(event => setQuery(event.target.value), []);

  return (
    <Background color="#abc">
      <MetaTags
        title="SSBM Rankings | Leon's Website"
        description="The best way to see every SSBMRank in one place, I guess"
        image="https://i.imgur.com/9Rz28w2l.png"
      />
      <SSBMRankContainer>
        <Title>SSBM Rankings</Title>
        <Options>
          <label>
            Show summer rankings:
            <input type="checkbox" onChange={onCheck} />
          </label>
          <form onSubmit={onSearch}>
            <FilterLabel>
              Filter:&nbsp;
              <FilterInput
                type="text"
                value={query}
                onChange={onChangeFilter}
                placeholder="tag, country, character"
              />
            </FilterLabel>
          </form>
        </Options>
        <RankingTables rankings={filteredRankings} showSummer={showSummer} />
        <PlayerTooltips />
      </SSBMRankContainer>
    </Background>
  );
};

export default React.memo(SSBMRank);

import styled from "styled-components";
import Flag from "./Flag";
import HeadIcon from "./HeadIcon";
import { Player } from "./types";

const CardComponent = styled.div`
  width: 400px;
  height: 200px;
  padding: 10px;
  border: 2px double black;
  border-radius: 10px;
  background-color: WhiteSmoke;
  pointer-events: none;
  user-select: none;
`;

interface PlayerCardProps {
  player: Player;
}

const PlayerCardHeader = styled.div`
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
`;

const PlayerCardHeaderCharacters = styled.div`
  display: flex;
  align-items: center;
  img:not(:first-child) {
    margin-left: 3px;
  }
`;

const PlayerCardRankingList = styled.div`
  height: 140px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  font-size: 16px;
`;

const PlayerCard: React.FC<PlayerCardProps> = ({ player }) => {
  return (
    <CardComponent>
      <PlayerCardHeader>
        {player.countries.map(country => (
          <Flag key={country} country={country} />
        ))}
        {player.tag}
        <PlayerCardHeaderCharacters>
          {player.mains.map(main => (
            <HeadIcon key={main} character={main} />
          ))}
          {player.secondaries.map(secondary => (
            <HeadIcon key={secondary} character={secondary} size={16} />
          ))}
        </PlayerCardHeaderCharacters>
      </PlayerCardHeader>
      <PlayerCardRankingList>
        {player.ranks.map(({ ranking, number }) => (
          <div key={ranking}>
            {ranking}: {number}
          </div>
        ))}
      </PlayerCardRankingList>
    </CardComponent>
  );
};

export default PlayerCard;

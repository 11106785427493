import { Helmet } from "react-helmet";

const MetaTags = (props: any) => {
  const { title = "leon.place", url, description = "Leon's website", image, type = "website" } = props;
  return <Helmet>
    {url && <meta property="og:url" content={url} />}
    <meta property="description" content={description} />
    <meta property="og:description" content={description} />
    <meta property="twitter:description" content={description} />
    <title>{title}</title>
    <meta name="title" content={title} />
    <meta name="og:title" content={title} />
    <meta name="twitter:title" content={title} />
    <meta name="og:image" content={image} />
    <meta name="twitter:image" content={image} />
    <meta property="og:type" content={type} />
    <meta property="twitter:type" content={type} />
  </Helmet>;
};

export default MetaTags;
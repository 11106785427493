import { RefObject, useCallback, useEffect } from "react";
import { Letter } from "../../shared/wordery/types";
import { W2_LETTERS } from "../../shared/wordsquared/constants";
import useOnLetterInput from "./hooks/useOnLetterInput";
import useStepCursor from "./hooks/useStepCursor";
import { useSubmitPlay } from "./hooks/useSubmitPlayCallback";
import {
  useToggleCursorDirection,
  useCenterCursorLocation,
} from "./recoil/game";
import { useZoomIn, useZoomOut } from "./recoil/camera";
import useOnDeletePendingMove from "./hooks/useOnDeletePendingMove";

const KEYBOARD_DIRECTIONS = {
  ArrowDown: [0, -1],
  ArrowUp: [0, 1],
  ArrowRight: [1, 0],
  ArrowLeft: [-1, 0],
};

const useOnKeyboardInput = (ref: RefObject<HTMLCanvasElement | null>) => {
  const toggleCursorDirection = useToggleCursorDirection();
  const onDeletePendingMove = useOnDeletePendingMove();
  const submitPlay = useSubmitPlay();
  const stepCursor = useStepCursor();
  const onLetterInput = useOnLetterInput();
  const centerCamera = useCenterCursorLocation();
  const zoomIn = useZoomIn();
  const zoomOut = useZoomOut();
  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const { key } = event;
      // console.log("🚀 / useOnKeyboardInput / key", key);
      if (key in KEYBOARD_DIRECTIONS) {
        const direction = KEYBOARD_DIRECTIONS[key];
        stepCursor(direction);
        event.preventDefault();
      } else if (W2_LETTERS.includes(key.toUpperCase())) {
        onLetterInput(key.toUpperCase() as Letter);
      } else if (key === "Enter") {
        submitPlay();
      } else if (key === "Shift") {
        toggleCursorDirection();
      } else if (key === " ") {
        centerCamera();
      } else if (key === "Backspace" || key === "Delete") {
        onDeletePendingMove();
      } else if (key === "PageDown") {
        zoomOut();
      } else if (key === "PageUp") {
        zoomIn();
      }
    },
    [
      stepCursor,
      onLetterInput,
      onDeletePendingMove,
      submitPlay,
      toggleCursorDirection,
      centerCamera,
      zoomOut,
      zoomIn,
    ]
  );

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    const canvas = ref.current;
    canvas.addEventListener("keydown", onKeyDown, true);
    return () => {
      canvas.removeEventListener("keydown", onKeyDown, true);
    };
  }, [onKeyDown, ref]);
};

export default useOnKeyboardInput;

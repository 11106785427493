import { Context, createContext, createRef, RefObject, useCallback, useContext } from "react";


const focuser = <T extends HTMLElement>(refContext: Context<RefObject<T>>) => () => {
  const ref = useContext(refContext);
  return useCallback(
    () => {
      ref.current?.focus();
    },
    [ref],
  );
};

export const CanvasRefContext = createContext(createRef<HTMLCanvasElement>());
export const useFocusCanvas = focuser(CanvasRefContext as any);

export const UsernameRefContext = createContext(createRef<HTMLInputElement>());
export const useFocusUsername = focuser(UsernameRefContext as any);

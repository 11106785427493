import normalize from "src/utils/normalize";
import { Rankings } from "./ranks";

const singleFilter = (rankings: Rankings, query: string): Rankings => {
  let isNegative = false,
    isExact = false;
  if (query.startsWith("-")) {
    isNegative = true;
    query = query.substring(1);
  }
  if (query.startsWith("=")) {
    isExact = true;
    query = query.substring(1);
  }
  if (query.length <= 1) {
    return rankings;
  }
  return rankings.map(ranking => {
    return {
      ...ranking,
      ranks: ranking.ranks.filter(playerRank => {
        if (isExact) {
          return (
            playerRank.searchValue.split(",").includes(query) !== isNegative
          );
        }
        return playerRank.searchValue.includes(query) !== isNegative;
      }),
    };
  });
};

const filterRankings = (rankings: Rankings, query: string): Rankings => {
  const queries = query.split(",").map(normalize);
  let outputRanks = rankings;
  queries.forEach(query => {
    console.log(query);
    outputRanks = singleFilter(outputRanks, query);
  });
  return outputRanks;
};

export default filterRankings;
